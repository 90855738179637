import { ContentFlat } from "@coreui/vue";

const nav: ContentFlat = [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/dashboard',
        fontIcon: 'fal fa-tachometer-alt',
        addLinkClasses: 'c-dashboard-link'
        // badge: {
        //   color: 'primary',
        //   text: 'NEW'
        // }
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Maintenance Records']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Browse',
        to: '/mr',
        fontIcon: 'fal fa-list',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Task',
        to: '/task',
        fontIcon: 'fal fa-tasks',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Labour Rate',
        to: '/labour-rate',
        fontIcon: 'fal fa-calculator',
      }, 
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Queue',
      //   to: '/mr/statuses',
      //   fontIcon: 'fal fa-layer-group',
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Callbacks',
      //   to: '/mr/offers',
      //   fontIcon: 'fal fa-phone',
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Timesheets',
      //   to: '/mr/offers',
      //   fontIcon: 'fal fa-stopwatch',
      // },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Parts']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Browse',
        to: '/part',
        fontIcon: 'fal fa-cubes'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Categories',
        to: '/part-category',
        fontIcon: 'fal fa-check'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Requests',
        to: '/part-request',
        fontIcon: 'fal fa-bags-shopping'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Locations']
      },
      /*{
        _name: 'CSidebarNavItem',
        name: 'Log',
        to: '/documents/logos',
        fontIcon: 'fal fa-list'
      },*/
      {
        _name: 'CSidebarNavItem',
        name: 'Tech Map',
        to: '/location/tech',
        fontIcon: 'fal fa-crosshairs'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Technicians']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Browse',
        to: '/technician',
        fontIcon: 'fal fa-users'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Timesheet Report',
        to: '/timesheet',
        fontIcon: 'fal fa-alarm-clock'
      },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Timesheets',
      //   to: '/tools/faq',
      //   fontIcon: 'fal fa-stopwatch'
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Locations',
      //   to: '/orders/offers',
      //   fontIcon: 'fal fa-map',
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Report Card',
      //   to: '/tools/report-card',
      //   fontIcon: 'fal fa-chart-line'
      // },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Payments']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Browse',
        to: '/payment',
        fontIcon: 'fal fa-money-check-alt'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Stripe',
        to: '/payment/stripe',
        fontIcon: 'fal fa-money-check-alt'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Customer']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Browse',
        to: '/customer',
        fontIcon: 'fal fa-users'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Sensor',
        to: '/sensor',
        fontIcon: 'fal fa-digital-tachograph'
      }
    ]
  }
]

export default nav;